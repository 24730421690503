export const VARS_KEYS = {
  IS_MAINTENANCE: 'IS_MAINTENANCE',
  IS_OUTBOUND_EMAIL_STOP: 'IS_OUTBOUND_EMAIL_STOP',
  FORMS_OF_PAYMENT: 'FORMS_OF_PAYMENT',
  VOTE_FIO_HANDLE: 'VOTE_FIO_HANDLE',
  MOCKED_PUBLIC_KEYS_FOR_BOARD_VOTE: 'MOCKED_PUBLIC_KEYS_FOR_BOARD_VOTE',
  API_URLS_MIN_VERSION: 'API_URLS_MIN_VERSION',
  API_URLS_DYNAMIC_FETCH: 'API_URLS_DYNAMIC_FETCH',
};

export const HEALTH_CHECK_TIME = 5000;
